import React from 'react'
import QuestionBlog from '../components/QuestionBlog'

const Question = () => {
  return (
    <div className='Question'>
        <QuestionBlog/>
    </div>
  )
}

export default Question