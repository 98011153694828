export const ru = {
    company: "Компания",
    aboutcompany: "О компании",
    certificates: "Сертификаты",
    partners: "Партнёры",

    katalog: "Каталог",
    plintus: "Плинтус",
    wallCorners: "Обойные уголки",
    rapids: "Пороги",
    plastCase: "Пластиковый наличник",
    blister: "Блистр",
    plinCounter: "Плинтус для столешницы",
    trade: "Торговые оборудования",
    archive: "Архив",

    news: "Новости",
    contacts: "Контакты",
    question: "Задать вопрос",

    more: "Подробнее",
    headSlideT1: "Пластиковый плинтус",
    headSlideT2: "Пороги с монтажным каналом",
    headSlideT3: "Декоративные накладки",
    headSlideT4: `Пластиковый плинтус "Хайтек"`,
    headSlide1: " имеет проверенную временем S-образную форму с центральной планкой. Легкосъемная планка плинтуса имеет надежную фиксацию благодаря усовершенствованной конструкции замка",
    headSlide2: " предназначены для декорирования стыков одноуровневых напольных покрытий. Применимы с различными напольными покрытиями: ковролином, паркетом, ламинатом, линолеумом. плиткой и т.д.",
    headSlide3: " не только защищают углы, но и улучшают внешний вид углов и проемов. Уголки выпускаются в широкой цветовой гамме и подбирается в зависимости от ситуации.АFА",
    headSlide4: " состоит из двух частей - задний монтажный профиль и цельная лицевая часть. Легкосъемность цельной лицевой части плинтуса и комплектующих облегчает монтаж и демонтаж. Мягкие кромки плинтуса обеспечивают пыле- и водонепроницаемость, способствуют сглаживанию неровностей стены и пола.",

    headItem1: "Низкие цены и широкий ассортимент",
    headItem2: "Широкая сеть представительств",
    headItem3: "Весь товар сертифицирован",
    headItem4: "Оперативная доставка товара",

    aboutUsT: "О нас",
    aboutUsT1: "ГК «Идеал»",
    aboutUsT2: "Ваш идеальный партнер",
    aboutUsP1: "является ведущей в Узбекистане рыночно-ориентированной, высокотехнологичной компанией полного цикла по производству пластиковых материалов для внутренней отделки помещений: от конструкторского бюро и дизайнерского отдела до промышленного выпуска качественной продукции и развитой системы дистрибуции..",
    aboutUsP2: "Нас называют «компанией первой цены», то есть создающей «погоду» на рынке. Логистически-выверенный и структурно-выстроенный производственно-сбытовой цикл и продуманная дистрибуторская политика обеспечивают постоянный рост и расширение географии продаж.",

    productsT: "Каталог товаров",

    inNumT: "Мы в цифрах",
    inNum1: "Сотрудников",
    inNum2: "Довольных клиентов",
    inNum3: "Лет опыта",

    partnersT: "Нам доверяют",

    requestT: "Консультация по продукции компании",
    requestP: "Менеджеры компании с радостью ответят на ваши вопросы и подготовят индивидуальное коммерческое предложение.",


    applT: "Оставьте заявку",
    inputName: "Ф.И.О*",
    inputTel: "Телефон номер*",
    inputCity: "Ваш город*",
    inputMsg: "Ваше сообщение*",
    cancel: "Отмена",
    send: "Отправить",

    aboutourcompany: "О нашей компании",
    aboutcompanyT: "Мы обеспечиваем высокое качество обслуживания с 2008 года",
    aboutcompanyP: "За годы существования компания Ideal зарекомендовала себя как надежного и ответственного производителя, в продукции которого вы можете быть уверены. На сегодняшний день предприятие обладает одним из крупнейших в Средней Азии автоматизированным производственным комплексом",
    ourTeam: "Наша команда",
    teamName1: "Дилмурод Гуломов",
    teamProf1: "Генеральный директор",

    ourContacts: "Наши контакты",
    address: "88G7+J9X, ул. Салар буйи, 35А, Ташкент, Узбекистан",
    grafik: "Пн-Сб 09:00 - 18:00",
    ourTelNum: "Наши номера",
    tel: "+998 98 368 33 73",
    orientir: "Ориентир: OASIS бизнес центр",

    questionT: "Всё еще остались у вас вопросы?",
    questionP: "Оставьте свои вопросы и получите ответы!",

    
    plinths: "Плинтусы",
    classic: "Классик",
    plinPart: "С цельной лицевой частью",
    hitech: "Хайтек",
    profiles: "Отделочные профили",
    corners: "Уголки",
    outer: "Раскладка наружная",


    mm: "мм",
    sm: "см",
    m: "м",
    kg: "кг",
    sht: "шт",

    plinDesc: "Плинтус Классик — современный продукт классической формы, декорирован реалистичными текстурами с глубоким визуальным 3D-восприятием и широким цветовым соответствием напольным покрытиям.",
    counterDesc: "Плинтус для столешницы «Идеал» – сконструирован для декорирования стыков поверхностей стены и столешницы. Плотное прилегание мягкой кромки плинтуса к стене и столешнице обеспечивает защиту от влаги и грязи. Может применяться и в ванных комнатах, так как устойчив к повышенной влажности и перепадам температур. Состоит из заднего монтажного профиля и лицевой части. ",
    codeProduct: "Код товара:",
    width: "Длина:",
    height: "Высота:",
    inthebox: "В коробке:",
    box: "Коробка:",
    weight: "Масса брутто:",
    package: "Упаковка:",
    complect: "Комплектующие",
    montaj: "Монтаж",
    decors: "Декоры",

    simpleCorner: "Угол обычный",
    araCorner: "Угол арочный",
    corDesc: "Продукция имеет широкий профильный и размерный ряд. Декоры совпадают с цветами плинтусов коллекции «Комфорт».",

    name: "Название продукта:",

    outerDesc: "Широкая цветовая палитра плиточных профилей позволяет с легкостью вписать их в дизайн Вашего интерьера.",

    distr: "Дистрибуция",
}